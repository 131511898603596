/* AnimatedGrid.css */
.overlay {
    z-index: -5;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.circle {
    flex: 1 0 auto;
    margin: 1px;
    border-radius: 50%;
    background-color: #c90000;
}
