/* App.css */
.myButton {
    margin: 18px;
}
.glow-green {
    box-shadow: 0 0 5vw 2vw #4caf48;
    margin: 1.5vw;
}
.glow-red {
    box-shadow: 0 0 5vw 2vw #f44336;
    margin: 1.5vw;
}
.glow-neutral {
    box-shadow: 0 0 5vw 2vw #2b2b2b;
    margin: 1.5vw;
}
body {
    background-color: #121212;
}
